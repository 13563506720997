<template>
  <div>
    <Card>
      <template #title>Grupos</template>
      <template #content>
        <Toolbar>
          <template #start>
            <InputText
              v-model="filtro['global'].value"
              autofocus
              type="search"
              minlength="3"
              required="required"
              :allowEmpty="false"
              :useGrouping="false"
              placeholder="Nome do grupo" />
          </template>
          <template #end>
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="p-button-success mr-2"
              @click="novoGrupo()" />
          </template>
        </Toolbar>

        <DataTable
          dataKey="id"
          :value="grupos"
          sortField="nome"
          :sortOrder="1"
          :filters.sync="filtro"
          :paginator="true"
          :rows="10"
          :globalFilterFields="['nome']"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} grupos"
          responsiveLayout="scroll">
          <Column field="nome" header="Nome do grupo"></Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Consignatarias'"
                class="p-button-rounded mr-2 mt-2"
                icon="pi pi-wallet"
                @click="exibirConsignatarias(slotProps.data)" />
              <Button
                v-tooltip.left="'Editar'"
                class="p-button-warning p-button-rounded mr-2"
                icon="pi pi-pencil"
                @click="editarGrupo(slotProps.data)" />
              <Button
                v-tooltip.left="'Excluir'"
                class="p-button-danger p-button-rounded mt-2"
                icon="pi pi-trash"
                @click="confirmaExclusao(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>

    <Dialog
      :visible.sync="grupoDialog"
      :style="{ width: '550px' }"
      header="Grupo"
      :modal="true"
      class="p-fluid">
      <div class="field">
        <label class="required" for="name">Nome</label>
        <InputText
          id="name"
          v-model.trim="grupo.nome"
          required="true"
          autofocus />
      </div>
      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="hideGrupo()" />
        <Button :disabled="saving" autofocus @click="salvarGrupo">
          <span v-if="!saving" class="pi pi-check"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </template>
    </Dialog>

    <Dialog
      :visible.sync="consignatariasDialog"
      header="Consignatárias"
      :modal="true"
      class="p-fluid">
      <div class="field col-12 md:col-12">
        <DataTable
          ref="dt"
          :value="grupo.consignatarias"
          dataKey="id"
          :paginator="true"
          :rows="10">
          <template #header>
            <div
              class="table-header flex flex-column md:flex-row md:justify-content-between">
              <span class="p-input-icon-left">
                <span v-if="loadingConsignataria"
                  >Carregando as consignatarias. Aguarde...</span
                >
                <AutoComplete
                  v-if="!loadingConsignataria"
                  v-model="buscaConsignataria"
                  :dropdown="true"
                  :placeholder="placeholder"
                  :suggestions="filtroConsignatarias"
                  field="razaoSocial"
                  @item-select="itemSelecionado($event)"
                  @complete="searchConsignataria($event)" />
              </span>
            </div>
          </template>

          <Column
            field="cnpj"
            header="CNPJ"
            :styles="{ 'min-width': '16rem' }"></Column>
          <Column
            field="razaoSocial"
            header="Razão Social"
            :styles="{ 'min-width': '12rem' }"></Column>
          <Column header="Ações" :styles="{ 'min-width': '12rem' }">
            <template #body="slotProps">
              <Button
                v-tooltip.top="'Remover'"
                class="p-button-danger p-button-rounded mr-2"
                icon="pi pi-times"
                @click="removerConsignataria(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </div>
      <template #footer>
        <Button
          label="Cancelar XXX"
          icon="pi pi-times"
          class="p-button-text"
          @click="consignatariasDialog = false" />
        <Button :disabled="saving" autofocus @click="salvarGrupo">
          <span v-if="!saving" class="pi pi-check"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
import GrupoService from '@/service/GrupoService'
import ConsignatariaService from '@/service/ConsignatariaService'

export default {
  data() {
    return {
      grupos: [],
      filtro: {},
      grupo: {},
      loading: false,
      loadingConsignataria: false,
      grupoDialog: false,
      consignatariasDialog: false,
      consignatarias: null,
      filtroConsignatarias: [],
      buscaConsignataria: null,
      placeholder: '',
      saving: false,
    }
  },

  created() {
    this.service = new GrupoService(this.$http)
    this.consignatariaService = new ConsignatariaService(this.$http)
    this.iniciarFiltro()
  },

  mounted() {
    this.loading = true
    this.service
      .getListaGrupos()
      .then((res) => {
        this.grupos = res.data
      })
      .catch((err) => {
        this.exibirToastErro(err)
      })
      .finally(() => {
        this.loading = false
      })
  },

  methods: {
    iniciarFiltro() {
      this.filtro = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      }
    },

    exibirConsignatarias(grupo) {
      this.loadingConsignataria = true
      this.consignatariaService
        .getListaConsignatariasAtivas()
        .then((res) => {
          this.consignatarias = res
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
        .finally(() => {
          this.loadingConsignataria = false
        })
      this.grupo = JSON.parse(JSON.stringify(grupo))
      this.consignatariasDialog = true
    },

    showGrupo() {
      this.grupoDialog = true
    },

    hideGrupo() {
      this.grupoDialog = false
    },

    salvarGrupo() {
      this.saving = true
      this.service
        .saveGrupo(this.grupo)
        .then((res) => {
          let idx = this.grupos.findIndex((el) => el.id === res.data.id)
          if (idx > -1) {
            this.$set(this.grupos, idx, res.data)
            this.exibeToastSucesso('Grupo atualizado com sucesso!')
          } else {
            this.grupos.push(res.data)
            this.exibeToastSucesso('Grupo criado com sucesso')
          }
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
        .finally(() => {
          this.saving = false
          this.consignatariasDialog = false
          this.grupoDialog = false
        })
    },

    confirmaExclusao(grupo) {
      this.grupo = JSON.parse(JSON.stringify(grupo))
      this.$confirm.require({
        message: 'Deseja excluir o grupo ?',
        header: 'Confirmação',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.excluirGrupo()
        },
      })
    },

    excluirGrupo() {
      this.service
        .excluirGrupo(this.grupo)
        .then(() => {
          let idx = this.grupos.findIndex((el) => el.id === this.grupo.id)
          this.grupos.splice(idx, 1)
          this.exibeToastSucesso('Grupo excluido com sucesso!')
        })
        .catch((err) => {
          this.exibirToastErro(err)
        })
        .finally(() => {})
    },

    novoGrupo() {
      this.grupo = {}
      this.showGrupo()
    },

    editarGrupo(grupo) {
      this.grupo = JSON.parse(JSON.stringify(grupo))
      this.showGrupo()
    },

    searchConsignataria(event) {
      this.filtroConsignatarias = this.consignatarias.filter((el) => {
        return el.razaoSocial.toLowerCase().includes(event.query.toLowerCase())
      })
    },

    itemSelecionado(event) {
      this.buscaConsignataria = null
      this.grupo.consignatarias.push(event.value)
      this.consignatarias = this.consignatarias.filter(
        (el) => el.id !== event.value.id,
      )
      this.atualizarPlaceholder()
    },

    removerConsignataria(data) {
      this.consignatarias.push(data)
      this.grupo.consignatarias = this.grupo.consignatarias.filter(
        (el) => el.id !== data.id,
      )
      this.atualizarPlaceholder()
    },

    atualizarPlaceholder() {
      if (this.consignatarias.length > 0) {
        this.placeholder = ''
      } else {
        this.placeholder = 'Nenhuma consignataria encontrada'
      }
    },

    exibirToastErro(err) {
      this.$toast.add({
        severity: 'error',
        summary: err.response.data.message,
        life: 10000,
      })
    },

    exibeToastSucesso(msg) {
      this.$toast.add({
        severity: 'success',
        summary: msg,
        life: 10000,
      })
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
::v-deep .p-picklist-source-controls {
  display: none;
}

::v-deep .p-picklist-target-controls {
  display: none;
}
</style>
