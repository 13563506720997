export default class GrupoService {
  constructor(http) {
    this._http = http
  }

  async getListaGrupos() {
    return this._http.get(`/api/grupos`)
  }

  async saveGrupo(grupo) {
    if (grupo.id) {
      return this._http.put('/api/grupos', grupo)
    } else {
      return this._http.post('/api/grupos', grupo)
    }
  }

  async excluirGrupo(grupo) {
    return this._http.delete(`/api/grupos/${grupo.id}`)
  }
}
